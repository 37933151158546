.payment-list {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.payment-item {
    background-color: white;
    border-radius: 8px;
    padding: 24px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

.payment-grid {
    display: grid;
    gap: 16px;
}

.payment-row {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 24px;
    align-items: center;
}

.label {
    color: #666;
    font-size: 14px;
}

.value {
    font-size: 14px;
    color: #333;
}

.course-link {
    color: #2563eb;
    text-decoration: none;
}

.course-link:hover {
    text-decoration: underline;
}

/* Responsive Design */
@media screen and (max-width: 600px) {
    .payment-item {
        padding: 16px;
    }

    .payment-row {
        grid-template-columns: 1fr;
        gap: 8px;
    }

    .label {
        color: #666;
    }

    .value {
        font-weight: 500;
    }
}

/* For very small screens */
@media screen and (max-width: 320px) {

    .payment-item {
        padding: 12px;
    }

    .payment-grid {
        gap: 12px;
    }
}