.certificate-block {
  width: 80%;
  margin: auto;
  background-color: white;
  margin-bottom: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 30px;
  border-radius: 16px;
  height: 70vh;
}

.certificate-desc {
  width: 350px;
  font-family: Inter;
  font-weight: 500;
  font-size: 24px;
  line-height: 33px;
  color: #64748b;
  text-align: center;
  padding: 40px 0;
}

.certificate-btn {
  width: 250px;
  padding: 14px;
  border: none;
  border-radius: 12px;
  background: linear-gradient(to right, #ff7373, #cd2525);
  font-family: Inter;
  font-weight: 500;
  font-size: 16px;
  color: white;
  cursor: pointer;
}

.certificate-btn:hover {
  background: linear-gradient(#ff7373, #cd2525);
  box-shadow: 0 0 3px #ff7373;
}

.appeal {
  max-width: 80%;

  margin: auto;
  background-color: white;
  border-radius: 16px;
  padding: 40px;
  margin-top: 20px;
  margin-bottom: 120px;
}

.appeal-flex {
  display: flex;
  justify-content: space-between;
  gap: 143px;
}

.appeal-text {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 70px;
}

.appeal-title {
  font-family: Inter;
  font-weight: 500;
  font-size: 24px;
  color: #64748b;
  padding-bottom: 20px;
}

.title-black {
  color: #000;
  font-size: 20px;
  text-align: center;
  padding-bottom: 22px;
  margin-top: 25px;
}

.appeal-text-left {
  max-width: 100%;
}

.appeal-doc {
  font-family: Inter;
  font-weight: 400;
  font-size: 24px;
  line-height: 31px;
  color: #64748b;
  margin-bottom: 24px;
}

.appeal-title-text {
  margin-bottom: 23px;
}

.appeal-img {
  max-width: 350px;
  padding: 10px;
  /* margin-right: auto; */
  margin-left: 30px;
}

.appeal-addres-block {
  display: flex;
}

.appeal-input {
  width: 100%;
  background-color: #eff1f9;
  opacity: 60%;
  border: none;
  font-family: Inter;
  font-weight: 400;
  font-size: 16px;
  padding: 16px;
  border-radius: 8px;
  margin-bottom: 20px;
  margin-top: 10px;
  outline: none;
  color: #676969;
}

.appeal-addres {
  display: flex;
  justify-self: center;
  gap: 24px;
}

.addres-title {
  font-family: Inter;
  font-weight: 500;
  font-size: 20px;
  padding-bottom: 10px;
  color: #64748b;
}

.appeal-link {
  text-decoration: none;
  font-family: inter;
  font-weight: 400;
  font-size: 16px;
  color: #64748b;
  margin-bottom: 8px;
  display: flex;
  gap: 8px;
  align-items: center;
}

.appeal-link i {
  font-size: 24px;
}

.appeal-link:hover {
  color: #cd2525;
}

.appeal-maps {
  width: 37%;
}

.appeal-submit {
  width: 100%;
  /* margin-left: auto; */
  display: flex;
  align-items: center;
  padding: 24px 0;
  gap: 13px;
}

.appeal-cancel {
  font-family: Inter;
  width: 180px;
  position: relative;
  display: inline-block;
  padding: 17px 16px;
  font-size: 20px;
  background: white;
  color: #cd2525;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  z-index: 1;
  overflow: hidden;
  transition: color 0.3s, background-color 0.3s;
}

.appeal-cancel::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  border-radius: 12px;
  background: linear-gradient(90.05deg, #ff7373 27.8%, #cd2525 98.65%);
  padding: 2px;
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
}

.appeal-cancel:hover {
  box-shadow: 0 0 3px #ff7373;
}

.appeal-confirm {
  width: 180px;
  padding: 17px 16px;
  border: none;
  border-radius: 12px;
  background: linear-gradient(to right, #ff7373, #cd2525);
  font-family: Inter;
  font-weight: 400;
  font-size: 20px;
  color: white;
  cursor: pointer;
}

.appeal-confirm:hover {
  background: linear-gradient(#ff7373, #cd2525);
  box-shadow: 0 0 3px #ff7373;
}

.certificates {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 20px;
  background-color: white;
  border-radius: 16px;
  margin-bottom: 50px;
}

.certificate-card {
  width: 380px;
  height: fit-content;
  border-radius: 12px;
}

.cert-img {
  width: 100%;
}

.cert-down {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0;
}

.down-text {
  font-family: Inter;
  font-weight: 400;
  font-size: 16px;
  color: #6e7079;
}

.down-btn {
  width: 160px;
  border: 1px solid #64748b;
  padding: 8px;
  font-family: Inter;
  font-weight: 400;
  font-size: 16px;
  color: #64748b;
  border-radius: 8px;
  background-color: white;
}

.appeal-submit-mobile {
  display: none;
}

.map-wrapper {
  display: none;
}

.certificate-request-complain {
  display: flex;
  justify-content: end;
  margin: 16px;
}

@media only screen and (max-width: 550px) {
  .certificate-namee {
    display: none;
  }

  .certificate-block {
    width: 100%;
    margin-bottom: 40vh;
    border-radius: 5px;
    height: 25vh;
  }

  .noCertificate {
    width: 140px;
  }

  .certificate-desc {
    width: 220px;
    font-size: 14px;
    line-height: 17px;
    padding: 20px 0;
  }

  .certificate-btn {
    width: 55%;
    padding: 12px 14px;
    border-radius: 3px;
    font-size: 12px;
    text-transform: uppercase;
  }

  .appeal {
    max-width: 100%;
    border-radius: 3px;
    padding: 20px;
    position: relative;
    margin: 0 16px 32px 16px;
  }

  .appeal-flex {
    flex-direction: column-reverse;
    gap: 0;
  }

  .appeal-text {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 0;
  }

  .appeal-title {
    font-size: 16px;
    margin-bottom: 16px;
  }

  .appeal-text-left {
    width: 95%;
  }

  .appeal-doc {
    font-size: 16px;
    line-height: 20px;
    padding-right: 6px;
  }

  .appeal-img {
    width: 100px;
    position: absolute;
    top: 0;
    right: 15px;
    transform: rotate(90deg);
  }

  .appeal-addres-block {
    display: flex;
    flex-direction: column;
  }

  .input-block {
    width: 100%;
  }

  .appeal-input {
    width: 100%;
    font-size: 16px;
    padding: 10px;
    border-radius: 8px;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .appeal-submit {
    display: none;
  }

  .appeal-submit-mobile {
    display: flex;
  }

  .appeal-submit-mobile .appeal-cancel,
  .appeal-submit-mobile .appeal-confirm {
    width: 50%;
    padding: 8px 16px;
    border-radius: 8px;
    font-size: 18px;
    font-weight: 400;
  }

  .appeal-cancel::before {
    border-radius: 8px !important;
  }

  .appeal-addres {
    width: 100%;
    margin-top: 32px;
    flex-direction: column;
    gap: 6px;
  }

  .addres-title {
    font-size: 16px;
  }

  .appeal-link {
    font-size: 14px;
    color: #64748b;
    padding: 5px 0;
  }

  .appeal-maps {
    width: 100%;
    margin-top: 20px;
  }

  .appeal-submit {
    width: 100%;
    justify-content: space-between;
    padding: 40px 0 20px 0;
  }

  .appeal-cancel {
    width: 130px;
    border-radius: 8px;
    margin-right: 0;
    font-size: 14px;
  }

  .appeal-cancel:hover {
    box-shadow: 0 0 5px #64748b;
  }

  .appeal-confirm {
    width: 130px;
    border-radius: 8px;
    margin-right: 0;
    font-size: 14px;
  }

  .cetificate-wrapper {
    height: 70vh;
  }

  .certificates {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 20px;
    background-color: white;
    border-radius: 16px;
    margin-bottom: 50px;
    margin-top: 16px;
  }

  .certificate-card {
    width: 380px;
    height: fit-content;
    border-radius: 12px;
  }

  .cert-img {
    width: 50%;
    margin-top: 8px;
    margin-bottom: 16px;
  }

  .cert-down {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 0;
  }

  .down-text {
    font-family: Inter;
    font-weight: 400;
    font-size: 16px;
    color: #6e7079;
  }

  .down-btn {
    width: 160px;
    border: 1px solid #64748b;
    padding: 8px;
    font-family: Inter;
    font-weight: 400;
    font-size: 16px;
    color: #64748b;
    border-radius: 8px;
    background-color: white;
  }

  .title-black {
    padding-bottom: 0px;
    margin-top: 0px;
  }

  .map-wrapper {
    display: flex;
    flex: 1;
    border-radius: 20px;
  }

  .map-wrapper iframe {
    width: 100%;
    height: 170px;
    border-radius: 12px;
    border: none;
  }
}
