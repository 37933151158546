* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300..700&display=swap');

.space_grotesk {
  font-family: "Space Grotesk", sans-serif !important;
}

.montserrat {
  font-family: "Montserrat", sans-serif;
}

.dm-sans {
  font-family: "DM Sans", sans-serif;
}

.inter {
  font-family: "Inter", sans-serif;
}

body {
  overflow-x: hidden;
}

html {
  scroll-behavior: smooth;
}

.videos-height{
  height: 80vh!important;
}

.loadingVideo{
  width: 100%;
  height: 80vh;
  border-radius: 16px;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

.pdf-component{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

@media only screen and (max-width: 550px) {
  .videos-height{
    height: auto!important;
  }
}