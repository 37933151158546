.news-container {
    width: 100%;
    display: flex;
}

.news-content{
    width: 65%;
    padding: 20px;
}

.news-name{
    font-family: Inter;
    font-weight: 600;
    font-size: 24px;
    color: #64748b;
    padding-bottom: 20px;
}

.news-picture{
    width: 100%;
    height: 400px;
    border-radius: 8px;
    object-fit: cover;
}

.news-date{
    padding: 10px 0;
}

.news-desc{
    font-size: 18px;
}

.news-right{
    width: 35%;
    padding: 20px;
}

.news-card{
    width: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 10px;
    margin-bottom: 20px;
}

.news-card-picture{
    width: 100%;
    height: 200px;
    border-radius: 8px;
    object-fit: cover;
}

.news-card-title{
    font-size: 16px;
    cursor: pointer;
}
.news-card-title:hover{
    color: #4000ff;
}

.news-card-desc{
    font-size: 14px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}