.content-container {
  min-height: 600px;
  background-color: white;
  border-radius: 16px;
  padding: 24px;
}

.content-title {
  min-height: 33px;
  font-size: 24px;
  font-weight: 600;
  line-height: 33px;
  margin-bottom: 18px;
}

.btn-go-chapter {
  padding: 9px 32px;
  border-radius: 12px;
  border: none;
  font-size: 16px;
  cursor: pointer;
  transition: opacity 0.15s ease-in-out;
  user-select: none;
  background-color: #CD2525;
  color: white;
  margin-top: 10px;
}

.btn-go-chapter:hover {
  opacity: 0.8;
}

.lesson-title {
  font-weight: 700;
  line-height: 20px;
  margin-bottom: 10px;
}

.lesson-text {
  font-weight: 400;
  line-height: 20px;
}

.content-text {
  margin-bottom: 16px;
  cursor: pointer;
  position: relative;
  padding: 28px;
  font-size: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  border-bottom: 1px solid #ddd;
}

.lesson-content-chapter {
  margin-bottom: 16px;
  background: #fff;
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}

.lesson-content-chapter-m {
  display: flex;
  gap: 10px;
}

.chapter-lesson-photo-l {
  width: 330px;
  height: 200px;
  object-fit: cover;
  border-radius: 8px;
  background: rgba(54, 53, 47, 0.1);
}

.number-static {
  position: absolute;
  left: -10px;
  top: -5px;
}

.chapter-content-l {
  background: #F5F6F8;
  border-radius: 16px;
  overflow: hidden;
  padding: 16px;
}

.lesson-content-chapter-m-text {
  padding: 10px 10px 10px 0;
}

.lesson-title-c {
  font-weight: 700;
  margin-bottom: 10px;
}

.lesson-title-m {
  font-size: 16px;
}

.lesson-content-chapter-m-file {
  padding: 10px;
}

.student-chapter-img{
  position: relative;
  width: 330px;
  height: 200px;
}

.chapter-lock{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(4px);
  border-radius: 8px;
  position: absolute;
  top: 0;
  left: 0;
}

.chapter-lock > img {
  width: 70px;
}

@media (max-width: 550px) {
  .content-container {
    padding: 16px;
    border-radius: 12px;
  }

  .content-title {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 12px;
  }

  .btn-go-chapter {
    padding: 8px 24px;
    font-size: 14px;
    border-radius: 10px;
  }

  .lesson-title {
    font-size: 16px;
    line-height: 18px;
    margin-bottom: 8px;
  }

  .lesson-text {
    font-size: 14px;
    line-height: 18px;
  }

  .content-text {
    padding: 20px;
    font-size: 18px;
    margin-bottom: 12px;
  }

  .lesson-content-chapter {
    padding: 8px;
    margin-bottom: 12px;
  }

  .lesson-content-chapter-m {
    flex-direction: column;
    gap: 8px;
  }

  .chapter-lesson-photo-l {
    width: 100%;
    height: auto;
  }

  .chapter-content-l {
    padding: 12px;
  }

  .lesson-content-chapter-m-text {
    padding: 8px 0;
  }

  .lesson-title-c {
    font-size: 16px;
    margin-bottom: 8px;
  }

  .lesson-title-m {
    font-size: 14px;
  }

  .lesson-content-chapter-m-file {
    padding: 8px;
  }

  .student-chapter-img {
    width: 100%;
    height: auto;
  }

  .chapter-lock {
    border-radius: 6px;
  }

  .chapter-lock > img {
    width: 50px;
  }
}
