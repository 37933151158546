.background {
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: -1;
  opacity: 0.4;
  background-size: 34px 34px;
  opacity: 0.4;
  background-size: 48px 48px;
  background-color: #c8baff33;
  /* background-image: repeating-linear-gradient(
    to right,
    #efeff3,
    #efeff3 2.4000000000000004px,
    #f7f7f7 2.4000000000000004px,
    #f7f7f7
  ); */
}
