.courses {
  padding: 40px 0;
  margin-bottom: 40px;
}

.courses-title {
  font-family: Inter;
  font-weight: 600;
  font-size: 24px;
  color: #64748b;
  text-align: center;
}

.courses-block {
  margin-top: 60px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 24px;
  justify-content: center;
}

.courses-card {
  width: 100%;
  background-color: white;
  border-radius: 16px;
  padding: 20px 13px;
  position: relative;
  padding-bottom: 70px;
}

.course-img {
  width: 100%;
  height: 170px;
  object-fit: cover;
  border-radius: 16px;
}

.pricee {
  display: flex;
  justify-content: flex-end;
  margin-top: 17px;
}

.price {
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  background: linear-gradient(to right, #ff7373, #cd2525);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-left: auto;
}

.course-name {
  font-family: Inter;
  font-weight: 600;
  font-size: 24px;
  color: #64748b;
  padding: 10px 0;
}

.course-author {
  font-family: Inter;
  font-weight: 500;
  font-size: 16px;
  color: #64748b;
  margin-bottom: 20px;
}

.card-bottom {
  position: absolute;
  bottom: 15px;
  right: 15px;
}

.course-number {
  font-family: Inter;
  font-weight: 400;
  font-size: 16px;
  color: #64748b;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;

  font-family: Arial, sans-serif;
}

.page-arrow-img {
  width: 10px;
  height: 10px;
}

.page-label {
  font-size: 16px;
  font-family: Inter;
  font-weight: 400;
  color: #3a3a49;
  padding-right: 10px;
}

.page-arrow-left,
.page-arrow-right {
  width: 10px;
  height: 10px;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  font-size: 18px;
  cursor: pointer;
  color: #333;
}

.page-arrow-left:disabled {
  color: #ccc;
  cursor: default;
}

.page-arrow-right:disabled {
  color: #ccc;
  cursor: default;
}
.page-number {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(90.05deg, #ff7373 27.8%, #cd2525 98.65%);
  color: white;
  border-radius: 50%;
  font-weight: bold;
  font-size: 16px;
  box-shadow: 0px 4px 4px 0px #4676fb40;
}

.page-select {
  font-size: 18px;
  padding: 3px;
  border: none;
  border-bottom: 1px solid #70668c;
  outline: none;
  background-color: inherit;
  padding-left: 10px;
}

.icon-none {
  display: none;
}

.course-img-box{
  width: 100%;
  position: relative;
}

.course-language{
  padding: 5px 10px;
  background-color: #fbfaff;
  border-radius: 6px;
  position: absolute;
  top: 10px;
  left: 10px;
}

.course-desc{
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media only screen and (max-width: 550px) {
  .courses {
    padding: 20px 0;
    margin-bottom: 40px;
  }

  .courses-title {
    font-weight: 500;
    font-size: 20px;
  }

  .courses-block {
    margin: 20px 16px 0 16px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px 10px;
    justify-content: center;
  }

  .course-author {
    margin-bottom: 20px;
    font-size: 12px !important;
  }

  .courses-card {
    width: 100%;
    border-radius: 9px;
    padding: 8px;
    position: relative;
  }

  .price {
    font-size: 12px;
  }

  .pricee {
    margin-top: 4px !important;
  }

  .course-name {
    padding: 8px 0;
    font-size: 15px;
    font-weight: 500;
    line-height: 1.1;
  }

  .course-number {
    font-size: 12px;
    color: #64748b;
    line-height: 14px;
  }

  .play-btn {
    display: flex;
    font-size: 12px;
    padding: 0px !important;
    justify-content: center;
    align-items: center;
    width: 70px !important;
    height: 24px !important;
  }

  .play-icon {
    width: 10px !important;
    height: 10px !important;
  }

  .page-label {
    display: none;
  }

  .icon-none {
    display: flex;
  }

  .page-number {
    display: none;
  }

  .page-select {
    display: none;
    padding-left: 10px;
  }

  .page-arrow-img {
    display: none;
  }

  .page-arrow-right,
  .page-arrow-left {
    background-color: #64748b;
    border-radius: 50%;
    width: 26px;
    height: 26px;
  }

  .page-arrow-right:hover,
  .page-arrow-left:hover {
    background: linear-gradient(90.05deg, #ff7373 27.8%, #cd2525 98.65%);
  }

  .page-arrow-right::after {
    content: "";
    width: 9px;
    height: 13px;
    background-image: url("../../images/next-arrow.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  .page-arrow-left::after {
    content: "";
    width: 9px;
    height: 13px;
    background-image: url("../../images/prev-arrow.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}
