@media only screen and (max-width: 550px) {
  .partners-info-title {
    margin-top: 20px;
  }

  .partners-info-left {
    padding: 0;
  }

  .partners-info-subtitle {
    margin-top: 0;
  }

  .partners-info-link {
    color: #3664da;
    margin: 16px 0;
    font-size: 20px;
    font-weight: 400;
  }

  .partners-info-img {
    width: 95%;
    margin: 10px auto;
  }

  .atc-text {
    margin-bottom: 20px;
  }

  .atc-img {
    width: 68%;
    margin: 20px auto;
  }

  .second-block {
    flex-direction: column-reverse;
  }
}
