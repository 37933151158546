.complaint-modal{
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.26);
    backdrop-filter: blur(4px);
    z-index: 5;
}

.complaint-modal-content{
    width: 600px;
    background-color: white;
    border-radius: 16px;
    padding: 40px;
    display: flex;
    flex-direction: column;
    min-height: 300px;
    position: relative;
    padding-top: 35px;
}

.complaint-input, .complaint-desc{
    padding: 10px;
    border: 1px solid rgb(162, 162, 255);
    outline-width: 1px;
    outline-color: rgba(0, 0, 255, 0.541);
    border-radius: 8px;
    margin-bottom: 20px;
}

.complaint-desc{
    height: 150px;
}

.complaint-btns{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.complaint-submit, .complaint-cancel{
    padding: 10px;
    width: 150px;
    border-radius: 8px;
    border: 2px solid #FF7373;
    background-color: inherit;
    cursor: pointer;
    font-size: 18px;
}

.complaint-submit{
    background: linear-gradient(to right, #FF7373, #CD2525);
    color: white;
    border: none;
    padding: 12px 10px;
}

.complaint-submit:hover{
    background: linear-gradient(#FF7373, #CD2525);
}

.complaint-cancel:hover{
    background-color: #CD2525;
    color: white;
    border: 2px solid #CD2525;
}

.cancel-complaint{
    width: fit-content;
    padding: 0 4px;
    font-size: 24px;
    background-color: #F4F3FF;
    border-radius: 8px;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    border: 1px solid rgba(0, 0, 255, 0.288);
}

.cancel-complaint:hover{
    background-color: #CD2525;
    color: white;
}

.cancel-complaint:hover .color-white{
    color: white;
}