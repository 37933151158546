.course-container {
    width: 100%;
    margin: 0 auto;
    padding: 1rem;
}

.course-card {
    background: white;
    border-radius: 8px;
    padding: 1.5rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.course-content {
    display: flex;
    gap: 1.5rem;
}

/* Book Cover */
.book-cover {
    flex-shrink: 0;
    width: 297px;
}

.book-cover img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Course Details */
.course-details {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.course-header {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.subtitle {
    color: #666;
    font-size: 0.875rem;
    margin: 0;
}

.title {
    font-size: 1.5rem;
    font-weight: 600;
    margin: 0;
}

.author {
    color: #666;
    font-size: 0.875rem;
    margin: 0;
}

/* Progress Bar */
.progress-section {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.progress-labels {
    display: flex;
    justify-content: space-between;
    color: #666;
    font-size: 0.875rem;
}

.progress-bar {
    width: 100%;
    height: 2px;
    background-color: #e5e7eb;
    border-radius: 1px;
    overflow: hidden;
}

.progress-fill {
    width: 0%;
    height: 100%;
    background-color: #2563eb;
    border-radius: 1px;
}

/* Course Count */
.course-count {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 0.875rem;
}

.dot {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: #2563eb;
}

/* Responsive Design */
@media (max-width: 768px) {
    .course-content {
        flex-direction: column;
        align-items: center;
    }

    .book-cover {
        width: 160px;
    }

    .course-details {
        width: 100%;
        text-align: center;
    }

    .progress-section {
        width: 100%;
    }

    .course-count {
        justify-content: center;
    }
}

.student-course-image{
    width: 100px;
    height: 250px;
}
