.partners-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  gap: 124px;
}

.partners-block:nth-child(odd) {
  flex-direction: row-reverse;
}

.partners-left {
  width: 48%;
  padding: 30px 0;
  
}

.partners-left-btn {
  width: 320px;
  transform: rotate(-15deg);
  position: relative;
  margin-bottom: 36px;
}

/* .employee-img {
    width: 741px;
    height: 416px;
} */
.first-desc {
  max-width: 550px;
}

.partners-left-btn::after {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #ff7676;
  position: absolute;
  top: 7px;
  right: 0;
}

.partners-left-btn::before {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #ff7676;
  position: absolute;
  top: 30px;
  right: -25px;
}

.partners-info {
  width: 300px;
  padding: 20px;
  border-radius: 92px;
  font-size: 28px;
  color: white;
  border: 8px solid #ff7676;
  background-color: #e11f1f;
}

.partners-info::before {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #ff7676;
  position: absolute;
  bottom: 15px;
  right: -10px;
}

.partners-left-title {
  font-family: Inter;
  font-weight: 600;
  font-size: 20px;
  color: #64748b;
  /* padding-top: 40px; */
  padding-bottom: 10px;
}

.partners-desc {
  font-family: Inter;
  font-weight: 400;
  font-size: 20px;
  color: #64748b;
  width: 100%;
  line-height: 26px;
  padding-top: 16px;
}

.partners-right {
  width: 50%;
  padding: 10px;
  display: flex;
  align-items: center;
}

.last-partner {
  justify-content: flex-end;
}

.partners-img {
  width: 400px;
  height: 250px!important;
  height: auto;
  border-radius: 12px;
  object-fit: cover;
}

.partners-img2 {
  width: 300px;
}

.partners-title {
  font-family: Inter;
  font-weight: 600;
  font-size: 24px;
  color: #64748b;
}

.left-title {
  padding: 10px 0;
}

@media only screen and (max-width: 550px) {
  .partners-block {
    flex-direction: column!important;
    gap: 0;
  }

  .partners-block-medium {
    flex-direction: column-reverse;
  }

  .partners-left {
    width: 100%;
    padding: 24px 0;
  }

  .partners-right{
    width: 100%;
  }

  .partners-left-btn {
    width: 160px;
    margin-bottom: 0px;
  }

  .partners-left-btn::after {
    width: 5px;
    height: 5px;
    top: 0;
    right: 0;
  }

  .partners-left-btn::before {
    width: 5px;
    height: 5px;
    top: 10px;
    right: -14px;
  }

  .partners-info {
    width: 95%;
    padding: 10px;
    border-radius: 35px;
    font-size: 11px;
    color: white;
    border: 3px solid #ff7676;
    background-color: #e11f1f;
  }

  .partners-info::before {
    width: 5px;
    height: 5px;
    bottom: 8px;
    right: -10px;
  }

  .partners-desc {
    font-size: 16px;
    line-height: 20px;
  }

  .partners-right {
    width: 100%;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .img-wrapper {
    width: 212px;
    height: 235px;
    margin-top: 30px;
  }

  .last-partner {
    margin: 30px 0;
  }

  .partners-img2 {
    width: 100%;
  }

  .partners-title {
    font-family: Inter;
    font-weight: 600;
    font-size: 24px;
    color: #64748b;
  }

  .left-title {
    padding: 10px 0;
  }

  .partners-left-title {
    padding-top: 10px;
    padding-bottom: 0px;
  }

  .offer-wrapper {
    padding-top: 6px;
  }

  .offer-title {
    margin-top: 0;
  }
}
