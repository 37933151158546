.info-wrapper {
    width: 800px;
    margin: auto;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 13px;
    margin-top: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.info-form-wrapper {
    max-width: 500px;
    /* margin-left: 80px; */
}

.info-form-button {
    display: flex;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
}

.profile-save{
    width: 100%;
    padding: 20px 15px;
}

.logout {
    margin-top: auto;
    padding: 15px 40px;
    background: linear-gradient(to right, #FF7373, #CD2525);
    border: none;
    color: white;
    font-size: 16px;
    cursor: pointer;
    border-radius: 8px;
}

.input-country{
    padding: 4px;
}

.file-info{
    display: flex;
    align-items: center;
    position: relative;
}

@media only screen and (max-width: 550px) {
    .info-wrapper {
        width: 100%;
        padding: 10px;
    }

    .info-form-wrapper {
        max-width: 100%;
    }

    .info-form-button {
        flex-direction: column;
        align-items: center;
    }
}