.certificates-wrapper {
    background-color: #ffffff;
    padding: 10px;
    border-radius: 13px;
    margin-top: 20px;
}

.student-certificates {
    max-width: 280px;
}

.student-certificates-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}

.student-download-certificate {
    border: 1px solid #64748B;
    padding: 3px 10px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 3px;
}