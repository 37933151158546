.phone-input-container {
    display: flex;
    gap: 10px;
    border-radius: 8px;
    align-items: center;
}

.phone-code {
    display: flex;
    align-items: center;
    background-color: #eff1f999;
    border-radius: 8px;
    padding: 5px;
    padding: 15px 5px;

}

.phone-code-select {
    border: none;
    background: transparent;
    font-size: 14px;
    outline: none;
    cursor: pointer;
}

.phone-number-input {
    width: 100%;
    padding: 15px 15px;
    border-radius: 8px;
    border: none;
    background-color: #eff1f999;
    margin: 10px 0;
    outline: invert;
    outline: none;
}