.play {
  width: 100%;
  padding: 20px;
  background-color: white;
  display: flex;
  border-radius: 8px;
  justify-content: space-between;
}

.play-img {
  width: 35%;
}

.video-block * {
  transition: none;
}

.play-block {
  width: 62%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.play-right {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.lesson-number {
  padding-bottom: 8px;
  margin-right: 30px;
}

.play-btn {
  position: relative;
  /* padding-right: 40px; */
  z-index: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  height: 41px;
  width: 124px;
}

/* .play-btn::after {
  content: "";
  width: 0;
  height: 0;
  border-bottom: 8px solid white;
  border-left: 8px solid transparent;
  position: absolute;
  top: 14px;
  right: 25px;
  transform: rotate(-45deg);
  cursor: pointer;
  z-index: 0;
} */

.warning {
  width: 800px;
  display: flex;
  align-items: flex-start;
  padding: 20px 0;
  margin-top: 10px;
  margin-bottom: 90px;
}

.warning-img {
  width: 18px;
  padding-top: 6px;
  margin-right: 5px;
}

.warning-text {
  font-family: Inter;
  font-weight: 400;
  font-size: 18px;
  line-height: 31px;
  color: #64748b;
}

.lessons-number {
  padding-top: 30px;
}

.lock-lesson {
  width: 100%;
  height: 180px;
  position: relative;
}

.lesson-img {
  width: 100%;
}

.block-lesson {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(4px);
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 16px;
}

/* .video-block {
  margin-top: 40px;
} */

.lesson-block {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px 0;
}

.complaint {
  padding: 10px 0;
}

.complaint-btn {
  border: 1px solid#64748B80;
  padding: 10px 15px;
  border-radius: 4px;
  font-family: Inter;
  font-weight: 500;
  font-size: 16px;
  background-color: inherit;
  color: #64748b;
  cursor: pointer;
}

.complaint-btn:hover {
  box-shadow: 0 0 5px #64748b;
}

.attention {
  padding: 2px 10px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: red;
  color: white;
  margin-right: 5px;
}

.videos {
  width: 100%;
  height: 600px;
  border-radius: 16px;
  overflow: hidden;
  margin-bottom: 30px;
  transition: none !important;
}

.write-block {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;
}

.written-material {
  width: 100%;
  background-color: white;
  border-radius: 16px;
  overflow: auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.material-block {
  height: fit-content;
  max-height: 500px;
}

.materials {
  font-family: Inter;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #64748b;
  padding-top: 20px;
  padding-bottom: 20px;
  user-select: none;
}

.downlaod {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: 11px;
}

.downlaod * {
  width: 189px;
}

.download-text {
  font-weight: 400;
  font-size: 16px;
  padding: 10px 46px;
  border-radius: 2px;
  background-color: rgba(245, 246, 248, 1);
  border: none;
  cursor: pointer;
}

.download-text:hover {
  color: rgba(54, 100, 218, 1);
}

.downlaod-btn {
  padding: 8px 10px;
  border: 1px solid #64748b;
  border-radius: 8px;
  font-family: Inter;
  font-weight: 400;
  font-size: 16px;
  color: #6e7079;
  cursor: pointer;
  background-color: inherit;
  text-decoration: none;
  text-align: center;
}

.downlaod-btn i {
  font-size: 22px;
}

.downlaod-btn:hover {
  box-shadow: 0 0 5px #64748b;
}

.next-lessons {
  margin-bottom: 50px;
}

.chat-container {
  width: 100% !important;
  height: 85vh !important;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background-color: white;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 16px;
  overflow: hidden;
  font-family: Inter;
}

.chat-title {
  font-family: Inter;
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  color: #64748b;
}

.chat-header {
  background-color: white;
  padding: 10px;
  border-bottom: 1px solid #70668c;
  display: flex;
  align-items: center;
}

.chat-name-block {
  padding-left: 10px;
}

.chat-name {
  font-family: Inter;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #45464e;
  padding: 10px 0;
}

.blue-text {
  color: #3664da;
}

.online-text {
  font-family: Inter;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #b6bfe8;
  position: relative;
  padding-left: 16px;
}

.online-text::after {
  content: "";
  width: 9px;
  height: 9px;
  background-color: #70668c;
  position: absolute;
  top: 4px;
  left: 0;
  border-radius: 50%;
}

.chat-body {
  padding: 10px;
  /* height: 370px; */
  overflow-y: auto;
  background-color: #ffffff;
}

.message {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  border-radius: 10px 10px 10px 0;
}

.message-bot {
  width: 80%;
  padding: 10px 20px 10px 34px;
  background-color: #5570f1;
  font-family: Inter;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: white;
  border-radius: 16px 16px 16px 0;
}

.message.user {
  background-color: #c8e6c9;
  align-self: flex-end;
}

.time {
  font-family: Inter;
  font-weight: 400;
  font-size: 16px;
  color: #8b8d97;
  margin-top: 5px;
}

.input-mes-block {
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: flex-end;
  margin: 10px 0;
}

.mes-block {
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.input-mes {
  max-width: 70%;
  width: fit-content;
  padding: 10px 20px;
  background-color: #8165d2;
  border-radius: 16px 16px 0 16px;
  margin-left: auto;
  font-family: Inter;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}

.time-user {
  display: block;
  margin-left: auto;
}

.chat-input {
  width: 100%;
  display: flex;
  align-items: center;
  border: 1px solid #64748b;
  border-radius: 16px;
  opacity: 80%;
  padding: 7px 5px;
}

.chat-input input {
  flex-grow: 1;
  border: none;
  outline: none;
  padding: 8px;
  font-family: Inter;
  font-weight: 400;
  font-size: 16px;
  color: #6e7079;
}

.chat-input button {
  background: none;
  border: none;
  cursor: pointer;
  color: #ffffff;
  padding: 10px 20px;
  background-color: #3664da;
  /* margin-right: 10px; */
  border-radius: 6px;
}

.chat-input button:hover {
  box-shadow: 0 0 2px #3664da;
}

.date {
  font-family: Inter;
  font-weight: 400;
  font-size: 14px;
  color: #1c1d22;
  text-align: center;
  padding: 15px 5px;
}

.materials-box {
  width: 100%;
  height: fit-content;
  box-sizing: border-box;
  overflow-y: auto;
}

/* .course-chat {
  width: 48%;
} */

.open-chat {
  display: none;
}

.lesson-num {
  display: none;
}

.line-desc {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 7.5em;
}

.line-descr{
  display: -webkit-box;
  -webkit-line-clamp: 6 ;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@media only screen and (max-width: 550px) {
  .video-wrapper {
    /* height: 400px; */
  }

  .play {
    margin-bottom: 20px;
    padding: 10px 15px;
    margin-top: 24px;
    margin-bottom: 24px;
    flex-direction: column;
    align-items: center;
  }

  .play-img {
    width: 100%;
    height: 200px;
    margin-bottom: 10px;
  }

  .following-lessons {
    margin: 24px 0 0 0;
  }

  .play-block {
    flex-direction: column;
    width: 100%;
  }

  .play-right {
    width: 100%;
    height: fit-content;
    align-items: flex-end;
    justify-content: space-between;
  }

  .warning {
    display: none;
  }

  .lesson-block {
    padding: 10px 0;
  }

  .lock-lesson {
    width: 100%;
    height: fit-content;
    position: relative;
  }

  .video-block {
    width: 100%;
    margin-top: 0;
    position: relative;
    padding-bottom: 1px;
    margin-bottom: 10px;
  }

  .video-name {
    position: absolute;
    left: 0;
    bottom: 10px;
  }

  .complaint {
    margin-left: auto;
    margin-bottom: 10px;
  }

  .complaint-btn {
    padding: 7px 12px;
    font-size: 14px;
  }

  .attention {
    padding: 1px 8px;
    width: 8px;
    height: 8px;
  }

  .videos {
    width: 100%;
    max-width: 800px;
    height: 217px;
    /* Adjust for responsiveness */
    border-radius: 16px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
    /* Adds the shadow effect */
    object-fit: cover;
    /* Ensures the video maintains its aspect ratio */
    overflow: hidden;
  }

  .video-title {
    font-size: 24px !important;
  }

  @media only screen and (max-width: 550px) {
    .video-title {
      display: none;
    }
  }

  .write-block {
    /* display: none; */
    flex-direction: column;
    margin-bottom: 30px;
  }

  .material-title {
    font-weight: 600;
    font-size: 20px !important;
  }

  .written-material {
    width: 100%;
    padding-bottom: 20px;
    margin-bottom: 30px;
  }

  .materials {
    overflow-y: auto;
  }

  .open-chat {
    display: block;
    width: 250px;
    margin: auto;
    margin-top: 40px;
    margin-bottom: 40px;
    border: 1px solid #3664da;
    border-radius: 4px;
    color: #3664da;
    padding: 10px;
    font-family: Inter;
    font-weight: 400;
    font-size: 16px;
  }

  .open-chat:hover {
    box-shadow: 0 0 3px #3664da;
  }

  .warning-hide {
    display: none;
    align-items: center;
    margin: 0;
    width: 100%;
    padding: 0;
  }

  .warning-text {
    font-size: 16px !important;
    line-height: 20px;
  }

  .warning-img {
    width: 40px;
  }

  .chat-container {
    width: 100%;
    height: 600px;
  }

  /* .chat-container {
    display: none;
  } */

  .hidden-active {
    display: flex;
    flex-direction: column;
  }

  .chat-title {
    font-size: 20px;
    line-height: 28px;
    padding-bottom: 10px;
  }

  .message {
    max-width: 100%;
  }

  .time {
    font-size: 12px;
  }

  .mes-block {
    width: 80%;
  }

  .input-mes {
    max-width: 100%;
  }

  .time-user {
    display: block;
    margin-left: auto;
  }

  .chat-input {
    padding: 5px;
  }

  .chat-input input {
    width: 80%;
    padding: 10px;
  }

  .chat-input button {
    font-size: 14px;
    width: 90px;
    padding: 8px 5px;
  }

  .chat-input button:hover {
    box-shadow: 0 0 2px #3664da;
  }

  .date {
    font-family: Inter;
    font-weight: 400;
    font-size: 14px;
    color: #1c1d22;
    text-align: center;
    padding: 15px 5px;
  }

  .message-bot,
  .message-user {
    width: 100%;
  }

  .course-desc {
    display: none;
  }

  .contens-wrapper {
    display: none;
  }

  .lesson-num {
    display: block;
  }

  .play-btn {
    padding: 14px 32px !important;
    height: auto;
    width: auto;
    margin-right: 16px;
  }
}

.next-btn-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 40px;
}

.next-btn {
  font-family: Inter;
  font-weight: 500;
  padding: 10px 30px;
  color: white;
  background: linear-gradient(to right, #ff7373, #cd2525);
  border: none;
  border-radius: 8px;
  cursor: pointer;
  height: fit-content;
}

.next-btn:hover {
  background: linear-gradient(#ffa0a0, #f52c2c);
}

.complaint-block {
  width: 100%;
  display: flex;
  align-items: end !important;
  justify-content: space-between;
  padding: 0 !important;
}