.footer {
  background-color: white;
  height: 60vh;
}

.footer-block {
  width: 90%;
  margin: auto;
  padding: 42px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 60vh;
}

.footer-logo {
  margin-bottom: 28px;
}

.footer-top {
  display: flex;
  align-items: flex-start;
  /* justify-content: space-between; */
}

.footer-left {
  display: flex;
  flex-direction: column;
  width: 75%;
  gap: 11px;
}

.footer-right {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.footer-link {
  font-family: Inter;
  font-weight: 500;
  font-size: 20px;
  color: #64748b;
  text-decoration: none;
  line-height: 27px;
  padding-bottom: 6px;
  width: fit-content;
}

.footer-link:hover {
  color: red;
}

.footer-title {
  width: 17%;
  font-family: Inter;
  font-weight: 600;
  font-size: 27px;
  line-height: 33px;
  /* padding-bottom: 15px; */
  color: #162d1f;
}

.footer-bottom {
  margin-top: auto;
  display: flex;
  align-items: center;
}

.footer-in {
  width: 74%;
  font-family: Inter;
  font-weight: 400;
  font-size: 13px;
  color: #162d1f;
}

.addres-block {
  display: flex;
  align-items: center;
}

.addres-link {
  text-decoration: none;
  font-size: 24px;
  padding: 5px;
  border-radius: 50%;
  color: #64748b;
}

.addres-link:hover i {
  color: #ff7676;
}

.footer-logo {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media only screen and (max-width: 550px) {
  .footer {
    height: 80vh;
    position: relative;
  }

  .footer-block {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .footer-logo {
    justify-content: center;
    padding: 0;
    padding-top: 40px;
  }

  .footer-top {
    display: flex;
    align-items: center;
    flex-direction: column-reverse;
    justify-content: center;
  }

  .footer-left {
    width: 100%;
    position: relative;
    top: 76%;
  }

  .footer-title {
    display: none;
  }

  .luanguag-select {
    display: none;
  }

  .footer-in {
    position: absolute;
    bottom: 36px;
    left: 25%;
  }

  .addres-block {
    position: absolute;
    top: 55%;
    left: 25%;
  }

  .close-btn {
    cursor: pointer;
  }
}
