.quiz {
  margin-bottom: 60vh;
}

.quiz-play {
  margin-top: 0;
}

.lesson-quiz {
  margin-bottom: 0;
}

.lesson-complaint {
  width: 100%;
  display: flex;
  align-items: end;
  /* margin-left: auto; */
  margin-bottom: 20px;
}

.write-quiz-block {
  width: 100%;
  align-items: stretch;
  justify-content: space-between;
}

.chat-wrapper {
  max-width: 345px;
}

.lessons-container {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.lesson-title {
  font-weight: 700;
  line-height: 20px;
}

.lesson-text {
  font-weight: 400;
  line-height: 20px;
}

.written-quiz {
  background-color: white;
  border-radius: 16px;
  padding: 20px;
  height: 100%;
}

.quiz-info {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  padding: 10px 0;
  color: #64748b;
  opacity: 60%;
}

/* .quiz-chat {
  width: 65%;
} */

.chat-title {
  text-align: center;
}

.quiz-chat-block {
  width: 100%;
}

.quiz-chat-body {
  height: 430px;
}

.score-number {
  width: 65%;
  margin-left: auto;
  padding: 20px;
  font-family: Inter;
  font-weight: 500;
  font-size: 20px;
  background-color: white;
  border-radius: 16px;
  margin-top: 15px;
  color: #64748b;
}

.quiz-modal {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #0000006b;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(4px);
  z-index: 99;
}

.quiz-modal-content {
  width: 550px;
  /* height: 450px; */
  background-color: white;
  padding: 20px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 40px;
}

.quiz-messeg {
  font-family: Inter;
  font-weight: 600;
  font-size: 24px;
  color: #4ac87b;
  line-height: 30px;
  padding: 20px;
}

.quiz-modal-score {
  font-family: Inter;
  font-weight: 400;
  font-size: 20px;
  color: #64748b;
  padding-bottom: 50px;
}

.quiz-modal-btns-wrapper {
  width: 100%;
  display: flex;
  gap: 16px;
  align-items: center;
}

.quiz-modal-btn {
  width: 50%;
  padding: 14px;
  color: white;
  background: linear-gradient(to right, #ff7373, #cd2525);
  border: none;
  border-radius: 12px;
  cursor: pointer;
}

.quiz-modal-btn:hover,
.retake-btn:hover {
  background: linear-gradient(#ff7373, #cd2525);
  box-shadow: 0 0 3px #ff7373;
}

.quiz-btn {
  width: 50%;
  padding: 12px;
  border-radius: 6px;
  cursor: pointer;
}

.retake-btn {
  color: #fff;
  background: linear-gradient(to right, #ff7373, #cd2525);
  border: 2px solid transparent;
}

.modal-back {
  color: #cd2525;
  background-color: white;
  border: 2px solid #ff7373;
}

.modal-back:hover {
  box-shadow: 0 0 5px #ff7373;
}

.quiz-modal-reply {
  width: 250px;
  font-family: Inter;
  font-weight: 400;
  font-size: 20px;
  line-height: 29px;
  color: #64748b;
  text-align: center;
  margin: 16px 0;
}

.title-questions{
  padding-bottom: 0!important;
}

@media only screen and (max-width: 550px) {
  .tests-wrapper {
    display: none;
  }

  .lesson-quiz {
    margin-bottom: 20%;
  }

  .quiz {
    position: relative;
    padding-bottom: 30px;
  }

  .lesson-name {
    margin-top: 24px;
    margin-bottom: 16px;
  }

  .quiz-complaint {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  .write-quiz-block {
    width: 100%;
    justify-content: space-between;
    flex-direction: column;
  }

  .written-quiz {
    width: 100%;
    background-color: inherit;
    padding-top: 0;
  }

  .quiz-book {
    font-size: 16px;
  }

  .quiz-info {
    font-size: 14px;
    line-height: 19px;
    padding: 2px 0;
  }

  .quiz-chat {
    width: 100% !important;
    display: flex;
    flex-direction: column;
  }

  .score-number {
    width: 100%;
    font-size: 16px;
  }

  .quiz-modal-content {
    width: 90%;
    margin: 0 auto;
  }

  .quiz-messeg {
    font-size: 16px;
    line-height: 15px;
    padding: 15px;
  }

  .quiz-modal-score {
    font-weight: 500;
    font-size: 14px;
    margin: 0;
    padding: 5px;
  }

  .success-img {
    width: 66px;
  }

  .quiz-modal-btn {
    width: 170px;
    padding: 12px;
    font-size: 12px;
    border-radius: 5px;
    margin-top: 20px;
  }

  .modal-back {
    width: 170px;
    padding: 12px;
    font-size: 12px;
    border-radius: 5px;
  }

  .modal-back:hover {
    box-shadow: 0 0 5px #ff7373;
  }

  .quiz-modal-reply {
    font-size: 14px;
  }
}
