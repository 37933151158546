.swiper {
  width: 100%;
  height: auto;
  padding-bottom: 50px;
}


.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.mySwiper {
  width: 100%;
  display: flex !important;
  align-items: flex-end !important;
  justify-content: flex-start !important;
}

.swiper-img {
  width: 100% !important;
  height: 70% !important;
  cursor: pointer;
}

.mySwiper {
  position: relative !important;
}





.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  bottom: 0;
  font-size: 24px;
  /* background-color: #64748b; */
  border-radius: 50%;
  width: 55px;
  height: 55px;
  border: 2px solid rgb(255, 255, 255);
  background-color: #b9b9b98f;
  transition: 0.5s ease-in-out;
  opacity: 0;
  visibility: hidden;
}

.swiper:hover .swiper-button-prev,
.swiper:hover .swiper-button-next {
  opacity: 1;
  visibility: visible;
  transition: 0.5s ease-in-out;
}

.swiper-button-prev::after {
  content: "";
  width: 12px;
  height: 12px;
  background-image: url("../../images/prev-arrow.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  padding: 5px 10px;
}

.swiper-button-prev:hover,
.swiper-button-next:hover {
  background: linear-gradient(90.05deg, #ff7373 27.8%, #cd2525 98.65%);
}

.swiper-button-next::after {
  content: "";
  width: 8px;
  height: 8px;
  background-image: url("../../images/next-arrow.svg");
  background-size: contain;
  /* Rasmni moslashtirish */
  background-repeat: no-repeat;
  background-position: center;
  padding: 5px 10px;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  position: relative;
  background: #fff;
  padding: 20px;
  border-radius: 8px;
}

.modal-image {
  max-width: 100%;
  max-height: 80vh;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
}

.swiper-box {
  display: flex !important;
  align-items: flex-start !important;
}

.swiper-slide {
  padding: 15px;
  display: flex;
  flex-direction: column;
  background-color: white;
  justify-content: flex-start;
  /* height: 370px; */
  width: 388px;
  border-radius: 8px;
}

.swiper-slider.active {
  /* height: 550px !important; */
  /* width: 380px !important; */
  /* Slightly lift the active slide */
  margin: 0 auto;
  box-shadow: 0px 20px 50px 0px #0000001a;
}

.swiper-slide-desc {
  font-family: Inter;
  font-weight: 400;
  font-size: 14px;
  color: #64748b;
  padding: 10px 0;
}

.certificate-slide {
  padding: 15px;
  display: flex;
  flex-direction: column;
  background-color: white;
  justify-content: flex-start;
  width: 180px !important;
  height: 250px !important;
  border-radius: 8px;
}

.certificate-slider{
  width: 280px;
}

.certificate-slider.active {
  width: 280px !important;
  height: 330px !important;
  margin: 0 auto;
  box-shadow: 0px 20px 50px 0px #0000001a;
}

.certificate-slide-desc {
  font-family: Inter;
  font-weight: 400;
  font-size: 14px;
  color: #64748b;
  padding: 10px 0;
}

.certificate-image {
  width: 100%;
  height: 220px;
  max-height: 260px;
}

.swiper-card-block {
  width: 100%;
  display: flex;
  justify-content: center;
}

.swiper-card {
  width: 350px !important;
  height: 200px;
}

.swiper-card.active {
  width: 350px !important;
  height: 200px;
}

@media only screen and (max-width: 550px) {
  .certificate-swiper .swiper-wrapper {
    /* align-items: flex-start !important; */
  }

  .swiper-block{
    display: none;
  }

  .certificate-image {
    width: 100%!important;
    height: 220px;
    object-fit: cover;
    border-radius: 3px;
  }

  .certificate-slider.active .certificate-image {
    width: 100%;
    height: 220px;
  }

  .certificate-slider{
    width: 350px!important;
  }


  /* .mySwiper {
    width: 100%;
    height: 100%;
  }

  .mySwiper .swiper-slide {
    width: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
  } */

  .swiper-card-block {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .swiper-card {
    width: 350px !important;
    height: 400px;
  }

  .swiper-card.active {
    width: 350px !important;
    height: 400px;
  }

  .mySwiper {
    width: 100%;
    height: 100%;
    padding: 0 !important;
    /* Ichki bo'shliqlarni olib tashlash */
    margin: 0 !important;
    /* Tashqi bo'shliqlarni olib tashlash */
  }

  .mySwiper .swiper-slide {
    width: 100% !important;
    height: 100%;
    margin-left: 0 !important;
  }

  .mySwiper {
    width: 100%;
    height: 100%;
    padding: 0 !important;
    margin: 0 !important;
    display: block !important;
  }

  .mySwiper .swiper-slide {
    width: 100% !important;
    height: 100%;
    margin-left: 0 !important;
  }

  .mySwiper .swiper-slide {
    width: 100% !important;
  }

  .swiper-block {
    padding-left: 16px;
  }

  .certificate-slide-desc {
    font-size: 8px;
    line-height: 11px;
    padding: 10px 0;
  }

  .certificate-slide {
    padding: 9px 8px;
  }
}