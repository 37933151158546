.container {
  max-width: 1200px;
  padding: 0 15px;
  margin: auto;
}

.tab-auth {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 16px 0;
}

.tab-auth-item {
  transition: 0.2ms;
  cursor: pointer;
  width: 100%;
  text-align: center;
  background: rgba(58, 58, 73, 0.24);
  padding: 12px 16px;
  border-radius: 8px;
  box-shadow: 0 1px 1px rgba(58, 58, 73, 0.24);
}

.tab-auth-item.active {
  width: 100%;
  text-align: center;
  background: rgba(59, 59, 207, 0.76);
  padding: 12px 16px;
  border-radius: 8px;
  box-shadow: 0 1px 1px rgba(58, 58, 73, 0.24);
}

.tab-auth-item.active p {
  color: white;
}

.tab-auth-item:hover {
  opacity: 80%;
}

.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0 40px 0;
  padding-top: 40px;
}

.toggle {
  display: none;
  width: 20px;
  height: 15px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
  cursor: pointer;
}

.toggle-line,
.toggle-line2 {
  width: 100%;
  height: 2px;
  background-color: #64748b;
}

.toggle-line2 {
  width: 80%;
  /* margin: 4px 0; */
}

.toggle:hover .toggle-line {
  background-color: red;
}

.toggle:hover .toggle-line2 {
  background-color: red;
}

.nav-list {
  list-style: none;
  display: flex;
  align-items: center;
  gap: 24px;
}

.nav-list-item {
  position: relative;
  display: inline-block;
}

.nav-link {
  text-decoration: none;
  font-family: DM Sans;
  font-weight: 700;
  font-size: 18px;
  line-height: 23.44px;
  color: #70668c;
}

.nav-link:hover {
  color: #ff7676;
}

.nav-link::after {
  content: "";
  position: absolute;
  left: 0;
  width: 0;
  bottom: 0;
  height: 2px;
  background-color: #ff7676;
  transition: width 0.3s ease-in;
}

.nav-link-active {
  width: 100%;
  color: #ff7676;
}

.nav-link-active::after {
  width: 100%;
}

.sign-icon {
  color: #64748b;
  cursor: pointer;
  display: none;
}

.button {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  padding: 10px 30px;
  color: white;
  background: linear-gradient(to right, #ff7373, #cd2525);
  border: none;
  border-radius: 8px;
  cursor: pointer;
  height: fit-content;
}

.button:hover {
  background: linear-gradient(to right, #ffa0a0, #f52c2c);
  box-shadow: 0px 24px 30px -16px #a4a4a4;
}

.modalLogin {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.322);
  backdrop-filter: blur(4px);
  transition: 0.5s ease-in-out;
  z-index: 5;
}

.modalLogin-content {
  width: 400px;
  margin: auto;
  background-color: white;
  padding: 24px;
  border-radius: 12px;
  max-height: 90vh;
  overflow: auto;
  scrollbar-width: none;
}

.modalLogin-title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.login-title {
  font-family: Inter;
  font-weight: 500;
  font-size: 20px;
}

.close {
  width: 30px;
  height: 30px;
  background-color: #70668c;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.close:hover img {
  background-color: #cd2525;
}

.text-check {
  font-family: Inter;
  font-weight: 500;
  font-size: 16px;
  padding-top: 30px;
  padding-bottom: 7px;
  color: #8b8d97;
}

.citizenship {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  margin: 10px 0;
  gap: 5px;
}

.citizenship-btn {
  max-width: 194px;
  font-family: Inter;
  font-weight: 400;
  font-size: 16px;
  border: none;
  background-color: inherit;
  padding: 22.5px 5px;
  border-radius: 6.91px;
  cursor: pointer;
  width: 100%;
  color: #000;
  text-align: center;
}

.citizenship-active {
  background-color: #4624bc;
  color: white;
}

.modalLogin-input {
  width: 100%;
  padding: 15px 15px;
  border-radius: 8px;
  border: none;
  background-color: #eff1f999;
  margin: 10px 0;
  outline: invert;
  outline: none;
}

.modalLogin-input:focus {
  box-shadow: 0 0 3px #8b8d97;
}

.input {
  margin-bottom: 20px;
}

.otp-info {
  color: #64748B;
  text-align: center;
  font-size: 13px;
}

.otp-info-number {
  font-size: 14px;
  margin: 8px 0;
}

.otp-timer {
  color: #3664DA;
  font-size: 16px;

}

.otp-btn {
  width: 100% !important;
  margin-top: 20px;
}

.submit-block {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

.cancel {
  width: 170px;
  padding: 13px 30px;
  border: 1px solid #cd2525;
  border-radius: 12px;
  color: #cd2525;
  background-color: white;
  font-family: Inter;
  font-weight: 400;
  font-size: 20px;
  cursor: pointer;
}

.cancel:hover {
  background-color: rgb(236, 233, 233);
}

.submit {
  width: 170px;
  width: 170px;
  padding: 15px 30px;
  border-radius: 12px;
  color: white;
  background-color: white;
  border: none;
  background: linear-gradient(to right, #ff7373, #cd2525);
  font-family: Inter;
  font-weight: 400;
  font-size: 20px;
  cursor: pointer;
}

.submit:hover {
  background: linear-gradient(#ffa0a0, #f52c2c);
}

.list-logo-block {
  display: none;
}

.luanguage-block {
  padding-right: 20px;
}

.nav-right{
  display: flex;
  align-items: center;
}

.luanguag-select {
  border: none;
  padding: 5px;
  background-color: inherit;
  font-family: Inter;
  font-size: 18px;
}

.chet-form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.chet-label {
  width: 100%;
  position: relative;
  font-size: 24px;
  color: #abafb1;
}

.chet-input {
  width: 100%;
  border: none;
  background-color: #eff1f999;
  outline: none;
  font-size: 16px;
  color: #abafb1;
  padding: 15px;
  border-radius: 8px;
  margin-top: 10px;
}

.ri-qr-scan-2-line {
  position: absolute;
  top: 20px;
  right: 10px;
}

.ri-calendar-2-line {
  position: absolute;
  top: 20px;
  right: 10px;
}

.chet-desc {
  font-weight: 400;
  font-size: 16px;
  color: #64748b;
  padding: 10px 0;
  line-height: 19.36px;
}

.required-text {
  color: #ff7676;
}

.myId {
  width: 100%;
  padding: 10px;
  font-weight: 400;
  font-size: 20px;
  background-color: #4825c2;
  border-radius: 12px;
  border: none;
  color: white;
  margin-top: 30px;
}

.gender-input{
  margin-right: 50px;
}

@media only screen and (max-width: 550px) {
  .container {
    padding: 0 16px;
  }

  .toggle {
    display: flex;
  }

  .navbar {
    padding-bottom: 0;
    padding-top: 10px;
  }

  .nav-list {
    opacity: 0;
    position: absolute;
    left: -200%;
    width: fit-content;
    width: 100%;
    height: 100vh;
  }

  .nav-list--active {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: fixed;
    top: 0;
    left: 0;
    padding: 40px;
    background-color: #a3a3a348;
    backdrop-filter: blur(37px);
    z-index: 5;
    transition: 0.5s ease-in-out;
    opacity: 1;
  }

  .list-logo-block {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20px;
  }

  .logo-link img {
    width: 78px;
    height: 34px;
  }

  .logo-link-nav {
    width: 60px;
  }

  .nav-list-item {
    padding-top: 6px;
  }

  .nav-link {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
  }

  .luanguage-block {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: auto;
  }

  .sign-icon {
    display: flex;
  }

  .nav-right{
    display: none;
  }

  .button {
    display: none;
  }

  .select-nav {
    display: flex;
  }

  .modalLogin-content {
    width: 374px;
    padding: 30px 20;
    border-radius: 12px;
  }

  .citizenship {
    padding: 6px 0;
    margin: 20px 0;
  }

  .citizenship-btn {
    width: 50%;
    font-size: 14px;
    border: none;
    background-color: inherit;
    padding: 24px 12px;
  }

  .citizenship-active {
    background-color: #4624bc;
    color: white;
  }

  .submit-block {
    gap: 22px;
  }

  .cancel {
    width: 50%;
    padding: 12px 10px;
    font-size: 16px;
  }

  .cancel:hover {
    background-color: rgb(236, 233, 233);
  }

  .submit {
    width: 50%;
    padding: 12px 10px !important;
    font-size: 16px;
  }

  .chet-form {
    margin-top: 14px;
    gap: 6px;
  }

  .myId {
    padding: 17px 10px;
  }

  .camera-container {
    width: 70%;
    aspect-ratio: 1;
    background: #eff1f999;
    border-radius: 50%;
    margin: 24px auto;
    position: relative;
    padding-top: 24px;
  }

  .camera-label {
    width: 100%;
    padding: 16px 0;
    background-color: #eff1f999;
    border-radius: 8px;
    text-align: center;
    margin-bottom: 10px;
  }

  .instructions {
    font-size: 14px;
    line-height: 1.5;
    padding: 0 12px;
  }

  .instructions span {
    color: #ff0000;
  }
}
