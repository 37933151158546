/* Layout styles */
.layout {
    display: flex;
    min-height: 100vh;
    max-height: 100vh;
}

/* Sidebar styles */
.sidebar {
    background-color: #fff;
    width: 250px;
    padding: 20px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    gap: 20px;
    transform: translateX(0);
    transition: transform 0.3s ease;
}

.menu-item {
    display: flex;
    align-items: center;
    gap: 5px;
}

.menu-item:hover {
    color: #FF7676;
}

.menu-active {
    color: #FF7676;
}


.menu-item:hover svg {
    fill: #ff5722;
    /* Match hover color */
}

.menu-active svg {
    fill: #ff5722;
    /* Match active color */
}

.sidebar .logo {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
}

.sidebar ul {
    list-style: none;
    padding: 0;
}

.sidebar ul li {
    margin: 15px 0;
    cursor: pointer;
}

.sidebar .logout {
    margin-top: auto;
    padding: 10px 20px;
    background: linear-gradient(to right, #FF7373, #CD2525);
    border: none;
    color: white;
    font-size: 16px;
    cursor: pointer;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    width: 157px;
    margin: auto;
}

.sidebar.open {
    transform: translateX(0);
}

.sidebar:not(.open) {
    transform: translateX(-100%);
}


.bottom-section {
    background-color: #FFF3F3;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px 0;
    margin-top: auto;
}

/* Burger menu button */
.burger-menu {
    display: none;
    position: fixed;
    top: 20px;
    left: 20px;
    font-size: 24px;
    background: none;
    border: none;
    cursor: pointer;
    z-index: 1000;
}

/* Main content styles */
.content {
    flex: 1;
    padding: 10px;
    background: #f9f9ff;
    height: 100vh;
    overflow-y: auto;
}

.student-navbar{
    display: none;
}

/* Responsive styles */
@media screen and (max-width: 550px) {
    .burger-menu {
        display: block;
    }

    .layout {
        flex-direction: column;
    }

    .student-navbar{
        display: flex;
        width: 100%;
    }

    .sidebar {
       display: none;
    }

    .content {
        margin-left: 0;
        padding: 0px;
    }
}