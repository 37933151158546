.card-form {
  width: 100%;
  margin: 32px 0;
}

.form-header {
  margin: 0 0 24px 0;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
}

.form-subtitle {
  margin: 16px 0;
  font-weight: 500;
  text-align: center;
}

.form-info {
  margin: 0 0 16px 0;
  color: #64748b;
  text-align: center;
}

.form-group {
  margin-bottom: 20px;
}

.form-label {
  display: block;
  margin-bottom: 8px;
  font-size: 14px;
}

.form-input {
  width: 100%;
  padding: 12px;
  border: none;
  border-radius: 7px;
  font-size: 16px;
  color: #333;
  background: #eff1f9;
}

.form-input::placeholder {
  color: #bbb;
}

.expiry-cvv-container {
  display: flex;
  gap: 40px;
  margin-bottom: 24px;
}

.expiry-cvv-container .form-group {
  flex: 1;
}

.toggle-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 18px;
}

.toggle-label {
  font-weight: 400;
}

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 44px;
  height: 22px;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ddd;
  transition: 0.4s;
  border-radius: 24px;
}

.toggle-slider:before {
  position: absolute;
  content: "";
  height: 19px;
  width: 19px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .toggle-slider {
  background-color: #2196f3;
}

input:checked + .toggle-slider:before {
  transform: translateX(20px);
}
