.header {
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-left {
  width: 55%;
}

.swiperBlock {
  width: 100%;
  height: 80vh !important;
  display: flex;
  justify-content: center;
  flex-direction: column;
  content: "";
  background: url(../../images/swiperImg1.png) no-repeat;
  background-size: cover;
  background-position: 100% 100%;
}

.swiperBlock2 {
  width: 100%;
  height: 80vh !important;
  display: flex;
  justify-content: center;
  flex-direction: column;
  content: "";
  background: url(../../images/swiperImg2.jpg) no-repeat;
  background-size: cover;
  background-position: 100% 100%;
}

.swiperContainer {
  width: 100%;
  margin: 0 auto;
}

.header-title {
  font-size: 58px;
  font-family: Inter;
  font-weight: 700;
  line-height: 70px;
  color: #64748b;
}

.yellow-text {
  color: #fecf1f;
  text-decoration: underline;
  text-decoration-thickness: 3px;
  text-underline-offset: 4px;
}

.header-desc {
  width: 530px;
  font-size: 20px;
  font-family: Inter;
  font-weight: 400;
  line-height: 28px;
  color: #64748b;
  padding: 20px 0;
  padding-bottom: 25px;
}

.header-img {
  max-width: 605px;
  width: 100%;
}

.btn {
  padding: 24px 57px;
  font-family: Inter;
  font-size: 16px;
  line-height: 14px;
  font-weight: 700;
  margin-top: 52px;
}

.info-block {
  width: 290px;
  margin-top: 120px;
  transform: rotate(-10deg);
  position: relative;
}

.info-btn {
  padding: 10px 30px;
  color: #41526a;
  font-family: Inter;
  font-weight: 700;
  line-height: 37px;
  font-size: 24px;
  background-color: #d8f1ff;
  border-radius: 92px;
  border: 8px solid #64748b;
  cursor: pointer;
}

.info-block::after {
  content: "";
  width: 25px;
  height: 4px;
  border-radius: 10px 10px 0 10px;
  background: radial-gradient(#ff7373, #cd2525);
  position: absolute;
  top: -10px;
  right: 0;
  transform: rotate(-55deg);
}

.info-block::before {
  content: "";
  width: 25px;
  height: 4px;
  border-radius: 10px 20px 0 10px;
  background: radial-gradient(#ff7373, #cd2525);
  position: absolute;
  top: 10px;
  right: -10px;
  transform: rotate(-25deg);
}

.info-btn::before {
  content: "";
  width: 25px;
  height: 4px;
  border-radius: 10px 20px 0 10px;
  background: radial-gradient(#ff7373, #cd2525);
  position: absolute;
  top: 35px;
  right: -14px;
}

.aboutPlatformDesc {
  display: none;
}

.knowledge {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 100px;
}

.knowledge-block {
  width: 50%;
  position: relative;
  padding: 40px 0;
}

.knowledge-back {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #c8baff2d;
  border-radius: 45%;
  filter: blur(25px);
}

.knowledge-desc {
  font-family: Inter;
  font-weight: 400;
  font-size: 24px;
  color: #64748b;
  line-height: 31px;
}

.knowledge-title {
  font-family: Inter;
  font-weight: 400;
  font-size: 48px;
  line-height: 58px;
  color: #64748b;
  padding-top: 40px;
}

.knowledge-block-box {
  width: 380px;
  background-color: white;
  border-radius: 8px;
  padding: 20px;
}

.students-title {
  font-family: Inter;
  font-weight: 600;
  font-size: 30px;
  line-height: 38px;
  padding: 15px 0;
  background: linear-gradient(to right, #ff7373, #cd2525);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.students-desc {
  font-family: Inter;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #64748b;
}

.knowledge-img {
  width: 150px;
}

.adven {
  background: linear-gradient(90deg, #d8f1ff 0%, #f6e7ff 100%);
  margin-top: 50px;
  padding-top: 58px;
}

.knowledge-right {
  width: 50%;
}

.knowledge-top {
  width: 100%;
}

.adventages {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.adventages-left {
  width: 53%;
}

.adventages-btn {
  font-family: Inter;
  font-weight: 700;
  font-size: 29px;
  color: #384557;
  padding: 20px 35px;
  border-radius: 92px;
  border: 8px solid #64748b;
  background-color: #ffc9e1;
  transform: rotate(-5deg);
}

.adventages-title {
  font-family: Inter;
  font-weight: 700;
  font-size: 44px;
  line-height: 61px;
  color: #64748b;
  padding: 62px 0 42px;
}

.adventages-right {
  width: 47%;
  display: flex;
  justify-content: flex-end;
}

.sell-img {
  width: 80%;
  margin-left: auto;
}

.swiper-wrapper {
  width: 100%;
  display: flex !important;
  align-items: flex-end !important;
  gap: 30px;
}

.swiper-slide {
  display: flex !important;
  overflow: hidden;
  width: 400px;
}

.swiper-slide-active {
  box-shadow: 0px 20px 50px 0px #0000001a;
}

.swiper-slide {
  opacity: 0 !important;
  z-index: 0;
  transition: opacity 0.5s ease-in-out;
}

.swiper-slide.swiper-slide-active {
  opacity: 1 !important;
  z-index: 1;
}

.swipers-image {
  width: 100%;
  max-height: 260px;
}

.swiper-img img {
  width: 100%;
  height: auto;
}

/* .swiper-img {
  max-width: 584px;
  max-height: 258px;
} */

.swiper-body {
  margin-top: 20px;
}

.more-btn {
  margin-top: 0;
}

.slide-desc {
  font-family: Inter;
  font-weight: 400;
  font-size: 14px;
  color: #64748b;
  line-height: 20px;
  padding: 5px 0;
  text-align: justify;
}

.to-right {
  margin-top: auto;
  margin-left: auto;
  padding: 15px 11px;
  background-color: #f8f5f5;
  border-radius: 46.08px;
  height: 30px;
  max-width: 62px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-card {
  width: 400px !important;
  height: fit-content;
}

.swiper-card.active {
  width: 480px !important;
  height: 420px;
}

@media only screen and (max-width: 550px) {

  .header {
    flex-direction: column;
  }

  .header-left {
    width: 100%;
    margin: 30px 0;
  }

  .header-title {
    font-size: 36px;
    line-height: 39px;
  }

  .header-desc {
    width: 80%;
    font-size: 16px;
    line-height: 22px;
    padding-bottom: 25px;
  }

  .header-img {
    width: 100%;
  }

  .info-block {
    width: 200px;
    margin-top: 90px;
    transform: rotate(-10deg);
    position: relative;
    margin-bottom: 26px;
  }

  .info-btn {
    padding: 8px 28px;
    line-height: 17px;
    font-size: 13px;
    border-radius: 42px;
    border: 3.7px solid #64748b;
  }

  .info-btn::before {
    top: 18px;
    right: 20px;
  }

  .info-block::after {
    width: 10px;
    height: 3px;
    top: -8px;
    right: 32px;
  }

  .info-block::before {
    width: 10px;
    height: 3px;
    right: 23px;
    top: 4px;
  }

  .info-btn::before {
    width: 10px;
    height: 3px;
  }

  .aboutPlatformDesc {
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: 10px auto;
  }

  .aboutPlatformTitle {
    font-size: 28px;
    font-weight: 400;
    line-height: 34px;
  }

  .knowledge {
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .knowledge-block {
    width: 85%;
  }

  .knowledge-desc {
    font-size: 16px;
    line-height: 20px;
  }

  .knowledge-title {
    font-size: 28px;
    line-height: 34px;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .knowledge-block-box {
    width: 85%;
    padding: 20px 30px;
    margin-bottom: 15px;
  }

  .students-title {
    font-size: 20px;
    line-height: 24px;
  }

  .knowledge-img {
    width: 105px;
  }

  .adven {
    background: linear-gradient(to bottom, #40bedb14, #74dbdb0d, #40bedb14);
    margin-top: 50px;
    padding-top: 35px;
  }

  .adventages-right {
    width: 47%;
    display: flex;
    justify-content: flex-end;
  }

  .sell-img {
    width: 80%;
    margin-left: auto;
  }

  .adventages {
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
  }

  .adventages-left {
    width: 90%;
  }

  .adventages-btn {
    font-size: 18px;
    padding: 12px 24px;
    border-radius: 55px;
    border: 4.84px solid #64748b;
  }

  .adventages-title {
    font-weight: 500;
    font-size: 28px;
    line-height: 35px;
    padding: 30px 0;
  }

  .adventages-right {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .sell-img {
    /* width: 330px; */
    margin-left: auto;
  }

  .knowledge-right {
    width: 100%;
  }

  .more-btn {
    width: 54%;
    display: block;
    padding: 13px;
    margin-bottom: 30px;
  }

  .to-right {
    padding: 2px 6px;
    border-radius: 46.08px;
    height: auto;
    align-content: end;
  }

  .swiper-wrapper {
    justify-content: center !important;
  }



  .swipers-image {
    width: 100%;
    height: 70px;
  }

  .slide-desc {
    font-size: 12px;
    line-height: 12px;
  }

  .swiperBlock,
  .swiperBlock2{
    align-items: center!important;
    background-size: cover;
    height: 50vh!important;
  }

  .swiperContainer{
    width: 70%;
  }

  .space_grotesk{
    width: 100%!important;
  }

  .header-title{
    width: 100%;
    font-size: 28px;
  }
}


.news {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
}

.news-block {
  width: 350px;
  box-sizing: border-box;
  padding: 20px;
  border-radius: 8px;
  margin-top: 30px;
  margin-bottom: 40px;
}

.news-img-line{
  overflow: hidden; 
  border-radius: 12px;
}

.news-img {
  width: 100% !important;
  height: 250px !important;
  margin-bottom: 10px;
  transition: transform 0.3s ease;
  object-fit: cover; 
}

.news-block:hover .news-img {
  transform: scale(1.1);
}


.news-title {
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 10px;
  color: #5e5e5e;
  text-decoration: none;
  text-align: start;
  line-height: 18px;
  cursor: pointer;
  padding-bottom: 30px !important;
}

.news-title:hover {
  color: #7375ff;
}

.news-text {
  font-size: 1em;
  color: #666;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  /* Limit to 5 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.space_grotesk{
  width: 50%;
}


@media only screen and (max-width: 550px) {
  .news-block {
    width: 100%;
  }

  .swiperContainer{
    width: 90%;
    margin: auto;
  }

  .header-title{
    color: #313131;
  }

  .header-desc{
    color: #222;
  }
}