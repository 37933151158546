.chat-container {
  width: 100% !important;
  height: 720px !important;
  background-color: white;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 16px;
  overflow: hidden;
  font-family: Inter;
}

.chat-title {
  font-family: Inter;
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  color: #64748b;
  padding-bottom: 20px;
}

.chat-header {
  background-color: white;
  padding: 10px;
  border-bottom: 1px solid #70668c;
  display: flex;
  align-items: center;
}

.chat-name-block {
  padding-left: 10px;
}

.chat-name {
  font-family: Inter;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #45464e;
  padding: 10px 0;
}

.blue-text {
  color: #3664da;
}

.online-text {
  font-family: Inter;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #b6bfe8;
  position: relative;
  padding-left: 16px;
}

.online-text::after {
  content: "";
  width: 9px;
  height: 9px;
  background-color: #70668c;
  position: absolute;
  top: 4px;
  left: 0;
  border-radius: 50%;
}

.chat-body {
  padding: 10px;
  /* height: 370px; */
  overflow-y: auto;
  background-color: #ffffff;
}

.message {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  border-radius: 10px 10px 10px 0;
}

.message * {
  color: white !important;
}

.message-bot {
  max-width: 70%;
  padding: 10px 20px 10px 34px;
  background-color: #5570F1;
  font-family: Inter;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: white;
  border-radius: 16px 16px 16px 0;
}

.message.user {
  background-color: #70668C;
  align-self: flex-end;
}

.time {
  font-family: Inter;
  font-weight: 400;
  font-size: 16px;
  color: #8b8d97 !important;
  margin-top: 5px;
}

.input-mes-block {
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: flex-end;
  margin: 10px 0;
}

.mes-block {
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.input-mes {
  max-width: 70%;
  width: fit-content;
  padding: 10px 20px;
  background-color: #8165d2;
  border-radius: 16px 16px 0 16px;
  margin-left: auto;
  font-family: Inter;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}

.time-user {
  display: block;
  margin-left: auto;
}

.chat-input {
  width: 100%;
  display: flex;
  align-items: center;
  border: 1px solid #64748B;
  border-radius: 6px;
  padding: 6px 11px;
}

.chat-input input,
.chat-input textarea {
  flex-grow: 1;
  border: none;
  outline: none;
  padding: 8px;
  font-family: Inter;
  font-weight: 400;
  font-size: 16px;
  color: #6e7079;
}

.send-button {
  border: none;
  cursor: pointer;
  color: #ffffff;
  padding: 8px 16px;
  background-color: #3664DA;
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 4px;
  font-weight: 400;
}

.chat-input button:hover {
  box-shadow: 0 0 2px #3664da;
}

.attach-button {
  border: none;
  cursor: pointer;
  background-color: transparent;
  /* margin-right: 10px; */
}

.attach-button:hover {
  background-color: transparent;
  outline: none;
  border: none;
}

.date-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 60px;
}

.date {
  font-weight: 400;
  font-size: 14px;
  color: #1c1d22;
  padding: 8px 12px;
  background-color: #f4f5fa;
  border-radius: 8px;
}

.reconnect-button,
.start-test-button {
  /* margin-left: 10px; */
  padding: 5px 10px;
  font-size: 12px;
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.reconnect-button:hover {
  background-color: #0056b3;
}

.typing-indicator {
  font-style: italic;
  color: #888;
  margin: 5px 10px;
}

.bot-microphone {
  margin-right: 20px;
  padding: 13px;
  border-radius: 50%;
  background-color: #5e83e1;
  color: white;
  cursor: pointer;
}

.bot-microphone:hover {
  box-shadow: 0 0 5px #5e83e1;
}

.stop-recording {
  cursor: pointer;
  margin-right: 20px;
  /* padding: 10px; */
  font-size: 39px;
  color: red;
}

table {
  width: 100%;
  border-collapse: collapse;
  /* Chiziqlarning birlashishiga yordam beradi */
}

table,
th,
td {
  border: 1px solid white;
  /* Chiziqlar qo'shiladi */
}

th,
td {
  padding: 8px;
  /* Maydonlar o'rtasida bo'sh joy */
  text-align: left;
  /* Yozuvni chapga joylashtirish */
}


@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  50% {
    transform: scale(1.2);
    opacity: 0.7;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.stop-recording {
  animation: pulse 1s infinite;
}

.vois-play {
  text-align: end;
  font-size: 30px;
  cursor: pointer;
}

.play-gif {
  width: 40px;
}

.help-title {
  width: 500px;
  font-size: 24px;
  text-align: center;
}

@media only screen and (max-width: 768px) {
  .chat-container {
    width: 100%;
    height: 100%;
    padding: 10px;
  }

  .chat-title {
    font-size: 20px;
  }

  .chat-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .chat-name-block {
    padding-left: 0;
  }

  .chat-name {
    font-size: 18px;
  }

  .online-text {
    font-size: 12px;
  }

  .message-bot,
  .message.user {
    max-width: 90%;
    padding: 8px;
    font-size: 12px;
    line-height: 20px;
    border-radius: 12px;
    margin-bottom: 8px;
    margin-top: 8px;
  }

  .help-title{
    font-size: 16px;
  }

  .bot-microphone {
    font-size: 12px;
    padding: 7px;
  }

  .stop-recording {
    font-size: 24px;
  }

  .send-button {
    padding:6px!important;
    font-size: 14px;
    width: 40px!important;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border-radius: 50%!important; */
  }

  .send-button img{
    width: 15px;
  }
  .sendText{
    display: none;
  }
}