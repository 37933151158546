.payment-title {
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  margin: 24px 0;
}

.payment-wrapper {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.paymanet-content {
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.content-value {
  font-weight: 500;
}

.course-price {
  color: #18743c;
  font-weight: 500;
}

.payment-methods {
  max-width: 400px;
  border-radius: 8px;
  overflow: hidden;
  margin-top: 36px;
}

.payment-option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 16px;
  background: #fff;
  border-bottom: 1px solid #eee;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 12px;
  transition: background-color 0.2s;
  box-shadow: 0px 4px 6px 0px #0000000f;
  margin-bottom: 16px;
  border-radius: 8px;
}

.payment-option.active {
  background: #efefef;
}

.payment-option:last-child {
  border-bottom: none;
}

.payment-option:hover {
  background-color: #f8f8f8;
}

.radio-container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.payment-logo {
  height: 24px;
  object-fit: contain;
  margin-left: auto;
}

.payment-name {
  font-size: 14px;
  font-weight: 400;
}

.payment-btns-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 0;
  gap: 13px;
  margin-bottom: 24px;
}

.payment-btns-wrapper button {
  width: 38%;
  padding: 10px 16px;
}
